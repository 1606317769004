.footer-top {
    background-color: #000000;
    padding-top: 20px;
    margin-top: 40px;

}

.footer-row{
    padding: 2vh 0;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.mobile-platforms {
    display: flex;
    flex-direction: row;
    a {
        padding: 0 1vw;
    }
}

.footer-top-right {
    .form-group {
        display: flex;
        flex-direction: row;
    }
}

.white-text{
    color: #ffffff;
}

.subscribe-email {
    margin: 0 1vw;
    border: none;
    background-color: #333333;
    color: #aaaaaa;
}

.subscribe-email-button {
    border: 1px solid #aaaaaa;
    background-color: black;
    width: 5rem;
}

.header-top {
    font-size: $font-size-small;
    background-color: white;
}

.header-top-row {
    padding: 5vmin;
    display: flex;
    justify-content: center;
    background: white;
    border-bottom: 0.05vh solid lightgray;
}

.header-top-left {
    display: flex;
    flex-grow: 1;
    justify-content: space-evenly;

    overflow: hidden;

    a {
        display: block;
        text-decoration: none;
        align-self: center;
        padding: 0 0.5vw;
        color: grey;
    }

    a:hover {
        color: $cdpink;
    }
}

.header-top-right {

    display: none;
    justify-content: end;
    overflow: hidden;

    a {
        display: block;
        text-decoration: none;
        padding: 0 0.5vw;
        align-self: center;
        color: black;
    }

    .header-top-right-link {
        padding: 0 0.5vw;
    }
    .header-top-right-link + .header-top-right-link {
        border-left: 0.05vw solid grey;
    }
}

@media (min-width: $breaking-point) {
    .header-top {
        background-color: $lightgray;
    }

    .header-top-row {
        padding: 1vh 0;
        background: $lightgray;
        border: none;
        justify-content: space-between;
    }

    .header-top-left {
        flex-grow: 0;
        background: $lightgray;

        a, a:hover {
            color: black;
        }

    }

    .header-top-right {
        display: flex;
    }
}

.footer-bottom{
    background-color: #333333;
    font-size: $font-size-small;


    .container {
        border-top: #aaaaaa;
    }
}

.copy-right {
    color: #aaaaaa;
}

.footer-bottom-nav {
    a {
        padding: 0 1vw;
        text-decoration: none;
        color: #aaaaaa;
    }
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
    border: .1rem solid lightgray;
    border-radius: $border-radius;
    margin: 1.5rem 0;

    > .form-control {
        position: relative;
        flex: 1 1 0%;
        border: none;

        &:focus {
            outline: none;
        }
    }
}

.pagination {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 2rem 0;

    .page-item {
        font: bold 2rem $font-family-roboto;

        &.active {
            color: $cdpink;
        }

        &.page-arrow {
            border: 0.5rem solid $cdpink;
            border-radius: 100%;
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 4rem;
            color: $cdpink;

            span, a {
                transform: translate(0, -5%);
            }

            &.disabled {
                color: $gray;
                border: 0.5rem solid $gray;
            }


        }
    }
}

.footer-mid{
    background-color: #000000;
    color: white;
    padding-bottom: 30px;
}
.footer-mid-title{

    font-size: $font-size-big;
}

.col ul {list-style: none;padding:0px;}
.col ul li{font-size:13px;color:#fff;margin:20px 0 0;}
.col ul li a{font-size:14px;color:#fff;}
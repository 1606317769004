.sticky-header {
    display: none;
    position: fixed;
    background-color: white;
    border-bottom: 0.5vh solid $cdpink;
    width: 100%;
    z-index: 5;
    top: 0;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}

@media(min-width: $breaking-point) {
    .sticky-header {
        display: block;
    }
}

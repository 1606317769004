.covid-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 8vh;
    background: darkgrey;
    color: white;
    padding: 3rem 2.5rem;
    

    p {
        font: bold 1.4rem/3rem $font-family-roboto;
        text-align: center;
    }

    .covidtextlink{
        font: normal 1.2rem/3rem $font-family-roboto;
        text-decoration: underline;
    }
}

.btn {
    display: block;
    font-size: $font-size-big;
    border: none;
    border-radius: $border-radius;
    text-align: center;
    box-sizing: border-box;
    cursor: pointer;

    transition: background-color .25s linear;
}

.btn--bg-pink {
    background-color: $cdpink;
}
.btn--bg-visne {
    background-color: $visne;
}

.btn--hover-green {
    &:hover {
        background-color: green;
    }
}

.btn--color-white{
    color: white;
}

.btn--w-100 {
    width: 100%;
}

.btn--search {
    background-color: white;
    height: 100%;
}

.btn--disabled {
    background-color: $gray;
}

.header-bottom {
    background-color: white;
}

.header-bottom-row {
    flex-flow: column wrap;
    justify-content: center;
}

.mobile-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 1vw;

    a {
        padding: 1vw;
    }
}

.logo {
    display: none;
}

.header-mid-row {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    background-color: white;
    justify-content: space-between;
    align-items: center;

    .logo {
        display: flex;
    }
}

.cart {

    display: flex;
    flex-shrink: 0;
    flex-flow: row nowrap;
    padding: 0.5rem;
    align-items: center;
    font-family: $font-family-roboto;

    .icon-cart {
        display: inline-block;
        background: url('/img/icon/icon-cart.png') no-repeat;
        margin-right: 0.5rem;
        width: 22px;
        height: 24px;
    }
}

.menu {
    display: flex;
    flex-direction: row;
    order: 4;
    z-index: 4;
}

.menu-list {
    display: block;

    width: 75%;
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    z-index: 4;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    clear: both;
    transition: width .2s ease-out;
}

.menu-icon {
    padding: 5vw;
    position: relative;
    cursor: pointer;

    .nav-icon {
        background: #333333;
        display: block;
        height: 0.25vh;
        width: 5vw;
        position: relative;

        &:before,
        &:after {
            background: #333333;
            content: "";
            display: block;
            height: 100%;
            width: 100%;
            position: absolute;
            transition: all .25s ease-out;
        }
        &:before{
            top: 0.75vh;
        }

        &:after {
            top: -0.75vh;
        }
    }
}

.menu-item {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #EEEEEE;
    padding: 1.5rem 1rem;
}

.menu-btn {
    display: none;
}

.menu-btn:checked ~ .menu-icon .nav-icon {
    background: transparent;
}

.menu-btn:checked ~ .menu-icon .nav-icon:before {
    transform: rotate(-45deg);
    top: 0;
}

.menu-btn:checked ~ .menu-icon .nav-icon:after {
    transform: rotate(45deg);
    top: 0;
}

.slide-enter-active,
.slide-leave-active{
    transition: width .25s linear;
}

.slide-enter,
.slide-leave-to{
    width: 0;
}

.tab-selector {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
    font-family: $font-family-oswald;
    font-size: $font-size-base;
    cursor: pointer;
    color: #666666;
    background-color: white;
    border: none;
    text-decoration: none;
    margin-left: 10px;
}

.tab-content {
    display: flex;
    padding: 1.5vm;
    flex-direction: column;
    color: $gray;
    font-size: $font-size-base;
    max-height: 100vh;
    a {
        transition: color .5s ease;

        &:hover {
            color: $visne;
        }
    }
}

.icon-img {
    display: inline;
}

.vip-logo {
    display: none;
}

@media (min-width: $breaking-point) {

    .menu {
        order: unset;
    }

    .menu-list {
        all: unset;

        display: flex;
        flex-grow: 1;
    }

    .menu-item {
        all: unset;
        padding: 1rem 1rem;
    }

    .menu-icon {
        display: none;
    }

    .tab-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        font-family: $font-family-montserrat;
        font-weight: normal;
        position: absolute;
        background-color: white;
        max-height: 225.3px;
        left: 0;
        right: 0;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        opacity: 0;
        padding: 5rem 5rem;
        transition: opacity .5s, transform .5s ease;
        transform: translateY(10%);
        z-index: 1;
        visibility: hidden;
    }

    .menu-item:hover .tab-content {
        visibility: visible;
        transform: translateY(0);
        opacity: 1;

    }

    .header-mid-row {
        order: 4;
        width: 100%;

        .logo {
            display: none;
        }

        border-top: 1px solid $lightgray;
    }

    .vip-logo {
        display: block;
        justify-self: self-end;
    }

    .logo {
        display: block;
        justify-self: self-start;
    }

    .header-bottom-row {
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
    }

    .mobile-icons {
        display: none;
    }
}

.myicn{

    color:#333333;
    margin-right: 5px;
    font-size: 20px;
}

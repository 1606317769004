.search-box {
    position: relative;
    padding: 0 10px 0 10px;
}

.search-data-list{
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    z-index: 5;
    background-color: black;

    &__item--search {
        flex: 0 0 33.33%;
    }
}

@media (min-width: $breaking-point) {
    .search-data-list {
        left: -5%;
        right: -5%;
    }
}
